function validatePhone(el) {
  var form = $(el).closest("form");
  $(form).validate({
    rules: {
      "question[phone]": {
        phonelength: true
      }
    },
    errorClass: "form-error",
    errorElement: "span",
    highlight: function (element, errorClass, validClass) {
      if (element.type === "radio") {
        this.findByName(element.name).addClass(errorClass).removeClass(validClass);
      } else {
        $(element).addClass(errorClass).removeClass(validClass);
      }
      $(element).tooltip("show");
    },
    unhighlight: function (element, errorClass, validClass) {
      if (element.type === "radio") {
        this.findByName(element.name).removeClass(errorClass).addClass(validClass);
      } else {
        $(element).removeClass(errorClass).addClass(validClass);
      }
      $(element).tooltip("hide");
    }
  });
}

function initPhoneValidations() {
  $(".phone-tooltip-validation").each(function () {
    $(this).prop("title", "Номер должен начинаться с 7 или 8 и содержать 11 цифр");

    validatePhone(this);

    $(this).tooltip({
      placement: "auto",
      trigger: "manual",
      template: '<div class="tooltip tooltip-error" role="tooltip">' + '<div class="arrow"></div>' + '<div class="tooltip-inner"></div></div>',
    });
  });
}

$(function () {
  initPhoneValidations();
});