import Cookies from 'js-cookie';
import ahoy from "ahoy.js";
import Rails from 'rails-ujs';

window.need_mark = function () {
  return gon.mark && Cookies.get("marked") == undefined;
}

window.trackQuestion = function(form) {
  if (window.need_mark()) {
    var event_name = "Question created through " + form + " form";
    window.gtag('event', event_name, {
      'event_category': 'Question',
    });
    window.ym(45092949, 'reachGoal', event_name);
    window.fbq('track', event_name);
    window.cg_conversion = { comment: event_name };
    ahoy.track(event_name);
    Cookies.set("marked", "true", {expires: 1});
  }
}

window.getGoogleClientId = function() {
  if (typeof(window.ga) !== 'undefined' && typeof(window.ga.getAll) === 'function') {
    var ga_client_id = '';
    window.ga.getAll().forEach( (tracker) => {
      ga_client_id = tracker.get('clientId');
    });
    return ga_client_id;
  } else {
    return '';
  }
}

window.getYandexClientId = function() {
  var ya_client_id = '';
  if (typeof(window.ym) !== 'undefined') {
    window.ym(45092949, 'getClientID', function(clientID) {
      ya_client_id = clientID;
    });
  }
  return ya_client_id;
}

$(document).ready(function () {
  (function track_yandex() {
    var ya_client_id = getYandexClientId();
    if (ya_client_id.length === 0) {
      setTimeout(track_yandex, 200);
    } else {
      Rails.ajax({
        type: "post",
        url: "/users/cid",
        data: "ycid=" + ya_client_id
      })
    }
  })();

  (function track_google() {
    var ga_client_id = getGoogleClientId();
    if (ga_client_id.length === 0) {
      setTimeout(track_google, 200);
    } else {
      Rails.ajax({
        type: "post",
        url: "/users/cid",
        data: "gcid=" + ga_client_id
      })
    }
  })();
});
