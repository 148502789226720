import 'select2';
import 'select2/dist/css/select2.css';
require('select2/src/js/select2/i18n/ru')

require('./city_selector')
require('./question_category_selector')

$(function () {
  $('#region_id').select2({
    placeholder: "Выберите регион",
    width: typeof (width) == "undefined" ? "100%" : width
  });

  $('.js--select2--default').select2();
});
