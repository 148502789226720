import Cookies from 'js-cookie';
import ahoy from "ahoy.js";

var show_and_track_phone;

show_and_track_phone = function(e) {
  var type;
  if (!$(this).data('clicked')) {
    $(this).text($(this).data('phone'));
    $(this).data('clicked', true);
    type = $(this).data("type");
    if (need_mark()) {
      gtag('event', 'Click phone to expand', {
        'event_category': 'Phone',
        'event_label': type
      });
      window.ym(45092949, 'reachGoal', "Click phone to expand", {
        type: type
      });
      //window.fbq('track', type);
      Cookies.set("marked", "true", {
        expires: 1
      });
      ahoy.track("Click phone to expand");
    }
    if (!gon.mobile) {
      e.preventDefault();
    }
  } else if (need_mark()) {
    gtag('event', 'Click phone to call', {
      'event_category': 'Phone',
      'event_label': type
    });
    window.ym(45092949, 'reachGoal', "Click phone to call", {
      type: type
    });
    //window.fbq('track', type);
    Cookies.set("marked", "true", {
      expires: 1
    });
    ahoy.track("Click phone to call");
  }
};

$(document).ready(function() {
  $('a[href^=\'tel\']').each(function() {
    $(this).click(show_and_track_phone);
  });
});
