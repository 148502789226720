var enable_button = function enable_button(button) {
  $(button).removeAttr("disabled");
}
var disable_button = function disable_button(button) {
  $(button).attr("disabled", "disabled");
  setTimeout(enable_button, 5000, button);
}
$(document).on('click', '.disengageable-btn', function () {
  var form = $(this).closest("form");
  if (form.data("validator") == undefined || form.valid()) {
    setTimeout(disable_button, 0, this);
  }
});