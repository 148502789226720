import Cookies from 'js-cookie';

var enable_button = function enable_button(button) {
  $(button).removeAttr("disabled");
}

var disable_button = function disable_button(button) {
  $(button).attr("disabled", "disabled");
  setTimeout(enable_button, 5000, button);
}

var auto_show = function auto_show() {
  return Cookies.get("popup_showed") != "true";
};

var show_form = function show_form() {
  $('.popup-form').show();
  $('.form-show-button').hide();
  $('.mobile-form-show-button').hide();
};

var close_form = function close_form(e) {
  e.preventDefault();
  $('.popup-form').hide();
  if (mobile_device) {
    show_mobile_button();
  } else {
    clearTimeout(popup_timer);
    $('.form-show-button').show();
  }
};

var submit_form = function submit_form(e) {
  var titleLength = $('#popup_question_question_title').val().length;
  if (titleLength === 0) {
    e.preventDefault();
    $('.error').html('<p class="text-danger">Введите свой вопрос</p>');
  } else {
    setTimeout(disable_button, 0, this);
    $('.error').html('');
  }
};

var show_mobile_button = function show_mobile_button() {
  var top = $('.ask-question-first').offset().top,
    sctop = $(this).scrollTop(),
    winh = $(this).height(),
    y = top - sctop - winh,
    form = $(".popup-form"),
    button = $('.mobile-form-show-button');
  if ((y > 0 || -y - 37 > winh) && !form.is(":visible") && (button.attr('hidden') != true)) {
    button.show();
  } else {
    button.hide();
  }
};

var mobile_device = ($(window).width() < 600) || gon.mobile,
  popup_timer;

$(document).ready(function () {
  var form = $(".popup-form");
  form.on('click', '.close', close_form);
  form.on('click', '.btn', submit_form);
  if (mobile_device) {
    $(window).on('scroll', show_mobile_button);
    $('.mobile-form-show-button').on('click', show_form);
    form.css({
      "left": 0,
      "width": "100%",
      "margin-left": 0
    });
  } else {
    $('.form-show-button').show();
    $(document).on('click', '.form-show-button', show_form);
  }
  if (auto_show() && !mobile_device) {
    popup_timer = setTimeout(show_form, 5000);
    Cookies.set("popup_showed", "true", {expires: 1});
  }
});
