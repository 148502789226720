function formatCategory (category) {
  if (!category.id) { return category.text; }

  var $category = $(
    '<span class="ml-' + category.depth * 3 + '">' + category.text + '</span>'
  );
  return $category;
};

function initQuestionCategorySelector(selector) {
  var selector = typeof(selector) == "undefined" ? "select#question_context_key_id" : selector;
  var element = $(selector);

  element.select2({
    templateResult: formatCategory,
    placeholder: "Выберите категорию",
    width: '100%',
    data: prepareDataForQuestionCategory(element.data("id"))
  });
}

function prepareDataForQuestionCategory(id) {
  var data = $.map(gon.categories_for_questions, function (obj) {
    if (id == obj.id.toString()) {
      obj.selected = true;
    }

    return obj;
  });
  return data;
}

function setup_cancel_edit(button, tag_link, selector_wrapper) {
  button.on('click', function() {
    $(this).hide();
    tag_link.show();
    selector_wrapper.html("");
  });
}

initQuestionCategorySelector();

$(document).ready(function() {
  $(document).on('click', '.show-category-selector', function(e) {
    e.preventDefault();
    var tag_link = $(this);
    var id = 'question_context_key_id_' + tag_link.data('qid');
    var key_id = tag_link.data('keyid');
    var selector_wrapper = tag_link.parent().find(".temp_select");
    var selector = '<select name="question[context_key_id]" id="' + id + '" data-id="' + key_id + '"><option value=""></option></select>';
    var cancel_button = tag_link.parent().parent().find(".cancel-edit");
    cancel_button.show();
    setup_cancel_edit(cancel_button, tag_link, selector_wrapper);
    selector_wrapper.append(selector);
    tag_link.hide();
    initQuestionCategorySelector("select#" + id);
  });
});
