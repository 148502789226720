import ahoy from "ahoy.js";

$(function () {
  $(document).on('click', ".show-phone-button", function() {
    $(this).siblings(".phone-wrapper").show();
    $(this).hide();
    ahoy.track("Phone Open");
    window.gtag('event', 'Open', {
      'event_category': 'Phone'
    });
    window.ym(45092949, 'reachGoal', "Phone Open");
    window.fbq('track', "Phone Open");
  });
});
